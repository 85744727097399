.intro{
    font-size: 2vw;
}

.nameDisplay{
    font-size: 5vw;
    padding-top: 1vw;
    padding-bottom: 1.5vw;
    font-weight: bold;
}

.project-img{
    height: 17vw;
    width: 17vw;
    /* padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;*/
    border-radius:1vw;
    vertical-align: middle;
}

.project-card{
    margin-top:5vw;
    margin-bottom:5vw;
    margin-left:20vw;
    margin-right:20vw;
    padding-left:1vw;
    padding-right:1vw;
    background-color: #deeff5;
    border-radius: 1vw;
    /* box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2); */
}

.project-image{
    align-items:center;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    border-radius: 0.5vw;
}

.project-title{
    /* padding: 0.5em; */
    font-size: 1.5vw;
}

.project-description{
    text-align: left;
    font-size: 1.3vw;
    /* align-items: center; */
    /* padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw; */
}

.project-content{
    /* margin-top: 2vh;
    margin-bottom: 2vh; */
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    display: flex;
    align-items: center; 
}

/* 
h3{
    margin-bottom: 1.2em;
} */

button{
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 1.5vw;
    color:#08629a;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    padding-left: 0.7vw;
    padding-right: 0.7vw;
}

button:hover{
    background-color: white;
    border-radius: 1vw;
    box-shadow: 1vw 1vw 1vw rgba(0,0,0,0.2)
}



@media all and (max-width: 480px) {
    img {
      width: 40vw;
      height: 40vw;
    }

    .project-img{
        height: 34vw;
        width: 34vw;
        /* padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        padding-left: 1.5vw;
        padding-right: 1.5vw;*/
        border-radius:1vw;
        vertical-align: middle;
    }
/* 
    .project-image{
        align-items:center;
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: 2vw;
        padding-bottom: 2vw;
        border-radius: 0.5vw;
    } */

    .project-card{
        margin-top:6vw;
        margin-bottom:6vw;
        margin-left:11vw;
        margin-right:11vw;
        padding-left:2vw;
        padding-right:2vw;
        background-color: #deeff5;
        border-radius: 1vw;
        /* box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2); */
    }

    button{
        background: transparent;
        border: none;
        box-shadow: none;
        font-size: 2.6vw;
        color:#08629a;
        padding-top: 1vw;
        padding-bottom: 1vw;
        padding-left: 1vw;
        padding-right: 1vw;
    }
    
    button:hover{
        background-color: white;
        border-radius: 1vw;
        box-shadow: 1vw 1vw 1vw rgba(0,0,0,0.2)
    }

    .project-title{
        /* padding: 0.5em; */
        font-size: 2.6vw;
    }
    
    .project-description{
        text-align: left;
        font-size: 2vw;
        /* align-items: center; */
        /* padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        padding-left: 1.5vw;
        padding-right: 1.5vw; */
    }
    
    .project-content{
        /* margin-top: 2vh;
        margin-bottom: 2vh; */
        padding-left: 2vw;
        padding-right: 2vw;
        padding-top: 2vw;
        padding-bottom: 2vw;
        display: flex;
        align-items: center; 
    }
  }